/** @typedef {import("components/comments/data/types").IComment} IComment */
/** @typedef {import("components/comments/data/types").ICommentReply} ICommentReply */
/** @typedef {import("components/comments/data/types").ICommentFormDataOut} ICommentFormDataOut */

import { Button, Group, Stack } from '@mantine/core';
import UploadFiles from 'components/files/upload/UploadFiles';
import { _t } from 'lang';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import CommentNotifiedPeople from 'components/comments/form/CommentNotifiedPeople';
import WysiwygEditor from 'components/inputs/wysiwyg/WysiwygEditor';
import { noop } from 'lodash';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import useLoadingAction from 'hooks/use-loading-action';
import useImmutable from 'hooks/use-immutable';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useDisclosure } from '@mantine/hooks';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import { nanoid } from 'nanoid';
import { useCommentData } from '../providers/CommentDataProvider';

/**
 * A form to edit an existing comment.
 *
 * @typedef {React.Ref<{
 *   focus: () => void;
 * }>} CommentEditFormRef
 *
 * @param {{
 *   commentEditFormRef?: CommentEditFormRef;
 *   comment: IComment
 *   autofocus?: boolean | 'start' | 'end' | 'all' | number | null
 *   onSubmit?: (data: ICommentFormDataOut) => void;
 *   onClose?: () => void;
 * }}
 */
export default function CommentEditForm({
  commentEditFormRef,
  comment,
  autofocus = false,
  onSubmit = noop,
  onClose = noop,
} = {}) {
  const { clientId } = useCommentData();
  const { getAction } = useApi();
  const { getFileMetadata } = useFileManager();
  const { confirm } = useConfirm();
  const [loading, submit] = useLoadingAction(onSubmit);
  const [loadingUsers, { close: stopLoadingUsers }] = useDisclosure(true);
  const [loadingAttachments, { close: stopLoadingAttachments }] = useDisclosure(true);
  const uploadFilesKey = useMemo(nanoid, [loadingAttachments]);
  const editorRef = useRef(null);

  const [data, updateData] = useImmutable(() => ({
    notify: comment.notified,
    attachments: [],
  }));

  /**
   * Confirms closing the comment form.
   */
  const confirmClose = useCallback(
    () =>
      confirm({
        title: _t('Stop editing'),
        message: _t('Are you sure you want to stop editing this comment? The changes will not be saved.'),
        onConfirm: onClose,
      }),
    [confirm, onClose]
  );

  /**
   * Handles the primary action.
   */
  const onPrimaryAction = () => {
    submit({
      ...data,
      text: editorRef.current?.getHTML(),
      mentioned: editorRef.current?.getMentioned(),
    });
  };

  // Fetch notified people.
  useEffect(() => {
    const commentGetNotifiedPeopleAction = getAction('CommentGetNotifiedPeopleAction');

    commentGetNotifiedPeopleAction({ parameters: { comment_id: comment.commentId } })
      .then((users) => updateData({ notify: users.map(({ user_id }) => String(user_id)) }))
      .catch(panic)
      .finally(stopLoadingUsers);
  }, []);

  // Fetch attachments.
  useEffect(() => {
    Promise.all(comment.attachments.map(({ fileId }) => getFileMetadata({ fileId })))
      .then((files) =>
        updateData({
          attachments: files.map(({ fileId, basename, mimeType }) => ({
            uuid: nanoid(),
            fileName: basename,
            fileType: mimeType,
            remoteId: fileId,
            progress: 1,
            finalized: true,
          })),
        })
      )
      .catch(panic)
      .finally(stopLoadingAttachments);
  }, []);

  useEffect(() => {
    if (commentEditFormRef) {
      commentEditFormRef.current = {
        focus: () => editorRef.current?.focus(),
      };
    }

    return () => {
      if (commentEditFormRef) {
        commentEditFormRef.current = null;
      }
    };
  }, [commentEditFormRef]);

  return (
    <Stack spacing={16}>
      <WysiwygEditor initialContent={comment.text} editorRef={editorRef} autofocus={autofocus} clientId={clientId} />

      <UploadFiles
        key={uploadFilesKey}
        initialFiles={data.attachments}
        onChange={(attachments) => updateData({ attachments: (attachments ?? []).map(({ fileId }) => fileId) })}
        loading={loadingAttachments}
        placeholderCount={comment.attachments.length}
      />

      <CommentNotifiedPeople
        value={data.notify}
        initialOpened={comment.notifiedPeopleCount > 0}
        onChange={(notify) => updateData({ notify })}
        loading={loadingUsers}
        placeholderCount={comment.notifiedPeopleCount}
      />

      <Group position="right" spacing={16} py={16}>
        <Button h={36} w={80} variant="link" disabled={loading} onClick={confirmClose}>
          {_t('Cancel')}
        </Button>
        <Button variant="primary" loading={loading} onClick={onPrimaryAction}>
          {_t('Comment')}
        </Button>
      </Group>
    </Stack>
  );
}
