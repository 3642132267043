/** @typedef {import('components/inputs/wysiwyg/extensions/ToolioImageContext').ICreateToolioImage} ICreateToolioImage */

import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/react';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import panic from 'errors/Panic';
import { Plugin } from 'prosemirror-state';
import { useCallback, useMemo, useState } from 'react';
import {
  WARNING_NOTIFICATION_COLOR,
  WYSIWYG_INLINE_IMAGE_MAX_HEIGHT,
  WYSIWYG_INLINE_IMAGE_MAX_WIDTH,
} from 'utils/constants';
import fitToBox from 'utils/fit-to-box';
import getImageDimensions from 'utils/get-image-dimensions';
import toBase64 from 'utils/to-base64';
import { ToolioImageContext, TOOLIO_IMAGE_NAME } from 'components/inputs/wysiwyg/extensions/ToolioImageContext';
import { IMAGE_UPLOAD_MAX_SIZE } from 'environment';
import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import formatFileSize from 'utils/format-file-size';

/**
 * A custom image extension for the wysiwyg editor.
 *
 * @param {{
 *   children: React.ReactNode;
 * }}
 */
export function ToolioImageProvider({ children }) {
  const { uploadFile } = useFileManager();
  const [loading, setLoading] = useState(false);
  const [resizing, setResizing] = useState(false);

  /**
   * Creates a ToolioImage node from the given image.
   *
   * @type {ICreateToolioImage}
   */
  const createToolioImage = useCallback(
    async (view, image) => {
      const contents = await toBase64(image);

      const fileId = await uploadFile({
        fileName: image.name,
        contents,
        isPublic: true,
      });

      const dimensions = await getImageDimensions(contents);

      const { width, height } = fitToBox({
        ...dimensions,
        maxWidth: WYSIWYG_INLINE_IMAGE_MAX_WIDTH,
        maxHeight: WYSIWYG_INLINE_IMAGE_MAX_HEIGHT,
      });

      const node = view.state.schema.nodes[TOOLIO_IMAGE_NAME].create({
        src: `/api/files/public/file/${fileId}`,
        alt: image?.name ?? '',
        title: image?.name ?? '',
        width,
        height,
      });

      return node;
    },
    [uploadFile]
  );

  const uploadImageOnPaste = useMemo(() => {
    return new Plugin({
      props: {
        handleDOMEvents: {
          /**
           * Handles the paste event.
           */
          async paste(view, event) {
            const images = Array.from(event.clipboardData.files).filter((file) => /image/i.test(file.type));

            if (images.length > 0) {
              event.preventDefault();

              setLoading(true);

              try {
                for (const image of images) {
                  if (image.size > IMAGE_UPLOAD_MAX_SIZE) {
                    showNotification({
                      color: WARNING_NOTIFICATION_COLOR,
                      title: _t('Image is too large'),
                      message: _t('Images larger than %s must be uploaded as attachments.', formatFileSize(IMAGE_UPLOAD_MAX_SIZE)), // prettier-ignore
                      autoClose: 10000,
                    });
                  } else {
                    const node = await createToolioImage(view, image);
                    const tx = view.state.tr.replaceSelectionWith(node);
                    view.dispatch(tx);
                  }
                }
              } catch (error) {
                panic(error);
              } finally {
                setLoading(false);
              }
            }
          },
        },
      },
    });
  }, [createToolioImage]);

  const ToolioImage = useMemo(() => {
    return Image.extend({
      name: TOOLIO_IMAGE_NAME,

      /**
       * Adds custom attributes to the image.
       */
      addAttributes() {
        return {
          ...Image.config.addAttributes(),
          width: { default: null },
          height: { default: null },
        };
      },

      /**
       * Parses the HTML element and returns the node's attributes.
       */
      renderHTML({ HTMLAttributes, node }) {
        const wrapper = document.createElement('div');
        wrapper.className = 'toolio-img-wrapper';

        const img = document.createElement('img');
        const attrs = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes);

        for (const [key, value] of Object.entries(attrs)) {
          if (value !== null) {
            img.setAttribute(key, value);
          }
        }

        const resizer = document.createElement('div');
        resizer.className = 'toolio-img-resizer';
        resizer.draggable = true;

        resizer.ondragstart = (event) => {
          event.stopPropagation();
          setResizing(true);

          img.dataset.startX = event.clientX;
        };

        resizer.ondrag = (event) => {
          event.stopPropagation();

          if (event.clientX === 0 && event.clientY === 0) {
            return; // One event before the drag ends outside the wysiwyg editor.
          }

          img.width += event.clientX - img.dataset.startX;
          img.dataset.startX = event.clientX;
        };

        resizer.ondragend = (event) => {
          event.stopPropagation();
          setResizing(false);

          img.width = Math.max(80, img.width);
          img.height = Math.max(80, img.height);

          node.attrs.width = img.width;
          node.attrs.height = img.height;

          img.removeAttribute('data-start-x');
        };

        wrapper.appendChild(img);
        wrapper.appendChild(resizer);

        return wrapper;
      },

      /**
       * Adds a custom plugin to the editor.
       */
      addProseMirrorPlugins() {
        return [uploadImageOnPaste];
      },
    });
  }, [uploadImageOnPaste]);

  const value = useMemo(
    () => ({ createToolioImage, ToolioImage, loading, resizing }),
    [createToolioImage, ToolioImage, loading, resizing]
  );

  return <ToolioImageContext.Provider value={value}>{children}</ToolioImageContext.Provider>;
}
