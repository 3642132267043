/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Tooltip']} TooltipTheme */

/** @type {TooltipTheme} */
export const Tooltip = {
  defaultProps: {
    withArrow: true,
    arrowSize: 10,
  },
  styles: () => ({
    tooltip: {
      backgroundColor: '#D4D4D4',
      padding: '8px',
      borderRadius: '8px',
      color: '#4D4D4D',
      fontSize: '12px',
      lineHeight: '16px',
      textAlign: 'center',
    },
    arrow: {
      width: '10px !important',
      height: '10px !important',
    },
  }),
};
