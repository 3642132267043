/** @typedef {import("components/comments/data/types").ICommentReply} ICommentReply */
/** @typedef {import("components/comments/data/types").ICommentFormDataOut} ICommentFormDataOut */

import { Button, Group, Stack } from '@mantine/core';
import UploadFiles from 'components/files/upload/UploadFiles';
import { _t } from 'lang';
import { useCallback, useEffect, useRef } from 'react';
import CommentNotifiedPeople from 'components/comments/form/CommentNotifiedPeople';
import WysiwygEditor from 'components/inputs/wysiwyg/WysiwygEditor';
import { noop } from 'lodash';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import useLoadingAction from 'hooks/use-loading-action';
import useImmutable from 'hooks/use-immutable';
import { useCommentData } from '../providers/CommentDataProvider';
import striptags from 'striptags';

/**
 * A form to add a new comment.
 *
 * @typedef {React.Ref<{
 *   focus: () => void;
 * }>} CommentCreateFormRef
 *
 * @param {{
 *   commentCreateFormRef?: CommentCreateFormRef;
 *   autofocus?: boolean | 'start' | 'end' | 'all' | number | null
 *   onSubmit?: (data: ICommentFormDataOut) => Promise<void> | void;
 *   onClose?: () => void;
 * }}
 */
export default function CommentCreateForm({
  commentCreateFormRef,
  notify = [],
  autofocus = false,
  onSubmit = noop,
  onClose = noop,
} = {}) {
  const { clientId, cache, setCache, resetCache } = useCommentData();
  const { confirm } = useConfirm();
  const editorRef = useRef(null);

  const [data, updateData] = useImmutable(() => ({
    notify: cache?.notify ?? notify,
    attachments: cache?.attachments ?? [],
    text: cache?.text ?? '',
  }));

  const onTextChange = useCallback((text) => updateData({ text }), [updateData]);

  const submitImpl = useCallback(
    async (data) => {
      await onSubmit(data);
      resetCache();
    },
    [onSubmit, resetCache]
  );

  const [loading, submit] = useLoadingAction(submitImpl);

  /**
   * Confirms closing the comment form.
   */
  const confirmClose = useCallback(
    () =>
      confirm({
        title: _t('Discard comment'),
        message: _t('Are you sure you want to discard this comment? The text will not be saved.'),
        onConfirm: () => {
          onClose();
          resetCache();
        },
      }),
    [confirm, onClose, resetCache]
  );

  /**
   * Handles a user being mentioned.
   *
   * @param {number} userId - The ID of the mentioned user.
   */
  const onUserMentioned = (userId) => {
    if (!data.notify.includes(String(userId))) {
      updateData({ notify: [...data.notify, String(userId)] });
    }
  };

  /**
   * Handles the primary action.
   */
  const onPrimaryAction = () => {
    submit({
      notify: data.notify,
      attachments: (data.attachments || []).map(({ fileId }) => fileId),
      text: editorRef.current?.getHTML(),
      mentioned: editorRef.current?.getMentioned(),
    });
  };

  useEffect(() => {
    if (commentCreateFormRef) {
      commentCreateFormRef.current = {
        focus: () => editorRef.current?.focus(),
      };
    }

    return () => {
      if (commentCreateFormRef) {
        commentCreateFormRef.current = null;
      }
    };
  }, [commentCreateFormRef]);

  useEffect(() => {
    const strippedText = striptags(data.text, ['img']);

    if (strippedText.length > 0 || data.attachments?.length > 0) {
      setCache(data);
    }
  }, [data, setCache]);

  return (
    <Stack spacing={16}>
      <WysiwygEditor
        editorRef={editorRef}
        onUserMentioned={onUserMentioned}
        autofocus={autofocus}
        clientId={clientId}
        onChange={onTextChange}
        initialContent={data.text}
      />
      <UploadFiles initialFiles={data.attachments} onChange={(attachments) => updateData({ attachments })} />
      <CommentNotifiedPeople value={data.notify} onChange={(notify) => updateData({ notify })} />
      <Group position="right" spacing={16} py={16}>
        <Button h={36} w={80} variant="link" disabled={loading} onClick={confirmClose}>
          {_t('Cancel')}
        </Button>
        <Button variant="primary" loading={loading} onClick={onPrimaryAction}>
          {_t('Comment')}
        </Button>
      </Group>
    </Stack>
  );
}
